{
  "all": "All",
  "all_flats": "All flats",
  "apply": "Apply",
  "avilable": "Avilable",
  "avilable_only": "Available only",
  "card_cookies": "By using this site, you agree to the terms of use of",
  "compass": {
    "n": "N",
    "s": "S",
    "w": "W",
    "e": "E"
  },
  "description": "Modern business class residential complex",
  "download": "Download",
  "duplex": "Duplex",
  "error": "Oops, something went wrong...",
  "finish": {
    "base": "Base",
    "white": "White",
    "full": "Full"
  },
  "status": {
    "free": "Sale",
    "rent": "Rent",
    "reserved": "Reserved",
    "sold": "Sold"
  },
  "level": "Level",
  "next": "More ",
  "not_found": "No apartments found!",
  "pc": "pcs",
  "per_page": "Show by:",
  "price_note": "The price depends on the storeys number and the apartment equipment",
  "requestMsg": "Your application has been successfully sent! We will contact you shortly. ",
  "requestPrice": "By request",
  "reset": "Reset all",
  "reserved": "Reserved",
  "sold": "Sold",
  "rent": "Rent",
  "sale": "Sale",
  "sorting": "Sorting:",
  "agreement": [
    "I agree with the terms and conditions of",
    "personal data",
    "processing"
  ],
  "callme": {
    "title": ["Request", "a call"],
    "submit": "Request a call",
    "info": "Leave your contact details and our managers will contact you shortly"
  },
  "fields": {
    "email": {
      "label": "E-mail",
      "ph": "Enter e-mail"
    },
    "name": {
      "label": "Name",
      "ph": "Enter the name"
    },
    "parking": "Parking space",
    "phone": {
      "label": "Phone",
      "ph": "Enter the phone number"
    },
    "storeroom": "Storeroom"
  },
  "map": {
    "touch": "Use two fingers to move the map",
    "scroll": "Use ctrl + scroll to zoom the map",
    "scrollMac": "Use \u2318 + scroll to zoom the map"
  },
  "nav": {
    "about": "About the Project",
    "architecture": "Architecture",
    "design": "Apartments design",
    "improvement": "Improvement and infrastructure",
    "parking": "Parking and storerooms",
    "location": "Location",
    "developer": "Developer",
    "flats": "Choosing an apartment",
    "floor": "Floor",
    "layout": "Layout",
    "contacts": "Contacts",
    "documentation": "Documentation",
    "cookies": "Cookies",
    "privacy": "Privacy Policy",
    "creation": "Creation and promotion",
    "contact_us": "Contact us",
    "process": "Construction process"
  },
  "request": {
    "title": ["Apartment", "application"],
    "text": "Leave your contact details and our managers will contact you shortly",
    "checks_caption": "Choose \"Parking space\" or \"Storeroom\", the manager will select the appropriate option for you",
    "submit": "Send"
  },
  "paginator": {
    "next": "forward",
    "back": "back"
  },
  "params": {
    "area": "Area, sq.m",
    "id": "№",
    "finish": "Finish",
    "flat_number": "Apartment No.",
    "floor": "Floor",
    "rooms": "Rooms",
    "rooms_qty": "Number of rooms",
    "price": "Price, €*",
    "price2": "Price, thousand EUR",
    "type": "Apartment type",
    "with_furniture": "Furnished",
    "without_furniture": "Unfurnished",
    "status_caption": "Status"
  },
  "table_sorting": {
    "floor": "By floor",
    "id": "By room number",
    "rooms": "By floor",
    "area": "By area",
    "finish": "By finishing",
    "price": "By price"
  },
  "validation": {
    "email": "The {field} field is filled incorrectly",
    "required": "The {field} field is manadatory"
  },
  "pages": {
    "architecture": {
      "title": "Residential complex architecture"
    },
    "contacts": {
      "title": "Contacts",
      "address_caption": "Sales office",
      "contacts_caption": "Contacts",
      "time_caption": "Working hours",
      "time": "Weekdays"
    },
    "cookies": {
      "title": "Cookies"
    },
    "developer": {
      "title": "Developer"
    },
    "documentation": {
      "title": "Documentation"
    },
    "design": {
      "title": "Apartments design"
    },
    "improvement": {
      "title": "Improvement \nand infrastructure"
    },
    "index": {
      "title": "Panorama Residence",
      "title_0": ["Business class", " modern \nresidential complex", null],
      "title_1": [null, "Residential complex\n", "architecture"],
      "title_2": "Improvement \nand infrastructure",
      "choose": "Choose an apartment",
      "more": "More details",
      "from": "From",
      "price": "Price per sq.m"
    },
    "flats-flat-id": {
      "title": "Apartment",
      "title_about": "About apartment",
      "title_plan": "Floor layout",
      "back": "Back",
      "share": "Share",
      "btn_request": "Submit your application",
      "btn_download": "Download"
    },
    "flats": {
      "title": "Choosing an apartment",
      "sub_title": ["Choice of parameters", "Choice of layouts"],
      "filter": "Filter",
      "floors": "Floors",
      "layouts": "Layouts",
      "table_link": "View"
    },
    "location": {
      "title": "Location"
    },
    "parking": {
      "title": "Parking and storerooms"
    },
    "process": {
      "title": "Construction process"
    },
    "privacy": {
      "title": "Privacy Policy"
    }
  },
  "page_404": {
    "title": "Page not found ",
    "btn": "Choose an apartment"
  },
  "design": "Apartments design",
  "close": "Close"
}
